import React from "react"
import { Box as GBox, Button } from "grommet"
import { Column, Box } from "gestalt"
import ReactMarkdown from 'react-markdown'
import { navigate } from "@reach/router"
import { Title, BoxWrapper, InfoWrapper } from "./styles"
import Image from "./image"
import FragmentWrapper from "../fragment-wrapper"

const genText = `

Hello, my name is Rex Isaac Raphael and I am a Senior Software Developer / Architect. 

My passion for programming was born at the age of 13 after being intimidated by a girl, younger than I, who could use the computer better than I could. From that day, I promised to learn everything I could about computers. 

I did almost everything a self-learning young teenager would- building websites, mobile games, DotNET WPF, ASP backends, Android Apps... anything I could lay my hands on. 

From those years, I garnered a vast amount of knowledge that has helped propel my career. In addition, being born and bred in the Delta's ensured hard work and discipline was engraved in my genes. 

As a creative developer and a "Christian", I don't believe in ‘impossibilities’.

Fast forward to today, I am a Senior Software Developer, (primarily programming in C#, Rust, Java, JavaScript/Typescript, Dart and Go Lang.). Currently living in Norway and working at [Kongsberg Digital](https://kongsberg.com).

In final, I dare you to think, never get contented, there's so much your mind can accomplish.
`

const AboutFragment = (props) => {
  return (
    <FragmentWrapper
      rightContent={() => <div />}
    >
      <GBox justify="center" align="center" fill pad={{ horizontal: 'large' }}>
        <GBox>
          <BoxWrapper>
            <GBox id="inf" justify="end">
              <Title>Hi im Rex.</Title> <br/>
              <Title float>senior software engineer / architect</Title>
            </GBox>
            <GBox id="img" style={{ float: 'right', maxHeight: 600 }}>
              <Image />
            </GBox>
          </BoxWrapper>
          <InfoWrapper justify="start" align="start">
            <ReactMarkdown source={genText} />
          </InfoWrapper>
          <Box flex paddingY={10} wrap>
            <Column lgSpan={4} md={5} mdSpan={5} span={12}>
              <Box padding={1}>
                <Button fill label="GET MY RESUME" primary />
              </Box>
            </Column>
            <Column lgSpan={4} md={5} mdSpan={5} span={12}>
              <Box padding={1}>
                <Button onClick={() => navigate('/works')} label="VIEW MY WORKS" fill />
              </Box>
            </Column>
          </Box>
        </GBox>
      </GBox>
    </FragmentWrapper>
  )
}
export default AboutFragment
