import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutFragment from "../components/fragments/about-fragment"

const AboutPage = (props) => (
  <Layout {...props}>
    <SEO title="About Me" />
    <AboutFragment />
  </Layout>
)

export default AboutPage
